<template>
  <v-dialog
    v-model="dialog"
    max-width="768px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <form @submit.prevent="save" data-vv-scope="trailer">
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row
              v-if="showMainForm"
              :class="{ 'TrailerForm__border-bottom': editItem !== 'new' }"
            >
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.number"
                  v-validate="'required|max:10'"
                  :error="errors.has('trailer.number')"
                  :error-messages="errors.first('trailer.number')"
                  name="number"
                  label="Trailer Number*"
                  prepend-icon="mdi-numeric-10"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-combobox
                  v-model="form.type"
                  v-validate="'required'"
                  :items="trailerTypes"
                  :error="errors.has('trailer.type')"
                  :error-messages="errors.first('trailer.type')"
                  name="type"
                  label="Trailer Type*"
                  prepend-icon="mdi-truck-trailer"
                />
              </v-col>

              <v-col v-if="form.type === 'Reefer'" cols="12" sm="6" md="4">
                <v-combobox
                  v-model="form.secondary_type"
                  :items="['ThermoKing', 'Carrier']"
                  label="Secondary Type"
                  prepend-icon="mdi-truck-trailer"
                  clearable
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-combobox
                    v-model="form.production_year"
                    :items="productionYears"
                    v-validate="'required'"
                    :error="errors.has('trailer.production_year')"
                    :error-messages="errors.first('trailer.production_year')"
                    name="production_year"
                    label="Year*"
                    prepend-icon="mdi-calendar-month"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="puDateMenu"
                  v-model="puDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.pu_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.pu_date"
                      label="Pick Up Date"
                      prepend-icon="mdi-calendar"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    :value="form.pu_date"
                    @input="
                      (v) => {
                        $refs.puDateMenu.save(v);
                        puDateMenu = false;
                      }
                    "
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  clearable
                  :items="dealerships"
                  v-model="form.dealership"
                  label="Dealership"
                  prepend-icon="mdi-domain"
                  item-text="name"
                  hide-details
                  return-object
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="form.make"
                  :items="makeOptions"
                  label="Make"
                  :item-text="formattedMake"
                  item-value="_id"
                  prepend-icon="mdi-truck-trailer"
                  return-object
                  @input="handleMakePicked"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.vin_number"
                  v-validate="
                    vinPrefix && !this.editId ? 'required|length:14' : 'required|length:17'
                  "
                  :error="errors.has('trailer.vin_number')"
                  :error-messages="errors.first('trailer.vin_number')"
                  label="Vin Number*"
                  name="vin_number"
                  prepend-icon="mdi-license"
                  :prefix="vinPrefix && !this.editId ? vinPrefix : ''"
                />
              </v-col>
            </v-row>

            <v-row
                v-if="showMainForm && editItem !== 'new'"
                :class="{ 'TrailerForm__border-bottom': editItem !== 'new' }"
            >
              <v-col cols="12" sm="8" md="6">
                <v-checkbox
                    v-model="form.in_company"
                    label="In company"
                    name="in_company"
                    :disabled="Boolean(form.out_of_company)"
                />
              </v-col>

              <v-col cols="12" sm="8" md="6">
                <v-checkbox
                    v-model="form.out_of_company"
                    label="Out of company"
                    name="out_of_company"
                    :disabled="Boolean(form.in_company)"
                />
              </v-col>
            </v-row>

            <!--   IN COMPANY FIELDS         -->
            <v-row
                v-if="showMainForm && editItem !== 'new' && form.in_company"
                :class="{ 'TrailerForm__border-bottom': editItem !== 'new' }"
            >

              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                    v-if="form.driver_state !== 'temporary'"
                    v-model="form.driver_name"
                    :items="driverOptions"
                    :loading="loading"
                    :search-input.sync="searchDrivers"
                    label="Driver"
                    placeholder="Start typing to search"
                    prepend-icon="mdi-database-search"
                    clearable
                    hide-details
                    hide-no-data
                    @change="handleDriverSelected"
                    @click:clear="handleClearDriver"
                >
                </v-autocomplete>
                <v-text-field
                    v-else
                    v-model="form.driver_name"
                    label="Driver"
                    prepend-icon="mdi-card-account-details-outline"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                    v-model="form.truck"
                    :items="trucksListFormated"
                    :search-input.sync="searchTrucks"
                    :loading="loading"
                    :filter="clientFilter"
                    label="Truck"
                    item-text="truck_id"
                    item-disabled="disabled"
                    hide-details="auto"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    name="truck"
                    clearable
                    return-object
                    hide-no-data
                    @click:clear="handleClearDriver"
                    @input="handleSelectTruck"
                >
                  <template v-slot:item="{ item }">
                      <span :class="{ isExternal: item.is_external }">
                        {{ `${item.truck_id} - ${item.name}` }}
                        <span v-if="item.is_external">external</span>
                      </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                    v-model="form.option"
                    :items="optionOptions"
                    prepend-icon="mdi-apple-keyboard-option"
                    item-text="label"
                    item-value="value"
                    clearable
                    label="Rent/Buy"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="form.company"
                    :items="companiesAll"
                    label="Company"
                    prepend-icon="mdi-domain"
                    item-text="name"
                    clearable
                    hide-details
                    return-object
                  />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-model="form.drivers_phone"
                    label="Driver's Phone"
                    prepend-icon="mdi-phone"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.start_date"
                        label="Start Date"
                        prepend-icon="mdi-calendar"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      :value="form.start_date"
                      @input="
                      (v) => {
                        $refs.startDateMenu.save(v);
                        startDateMenu = false;
                      }
                    "
                      no-title
                      scrollable
                  />
                </v-menu>
              </v-col>
            </v-row>
            <!--   OUT OF COMPANY FIELDS         -->
            <v-row
                v-if="showMainForm && editItem !== 'new' && form.out_of_company"
                :class="{ 'TrailerForm__border-bottom': editItem !== 'new' }"
            >
              <v-col cols="12" sm="6" md="4">
                <v-checkbox
                    v-model="form.temporary_third_party"
                    label="Temporary third party?"
                    :error="errors.has('trailer.temporary_third_party')"
                    :error-messages="
                      errors.first('trailer.temporary_third_party')
                    "
                    name="temporary_third_party"
                    @change="form.third_party = null"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-if="form.temporary_third_party"
                    v-model="form.third_party"
                    label="Third Party"
                    prepend-icon="mdi-domain"
                    @input="handleThirdPartyPicked"
                />
                <v-autocomplete
                    v-else-if="form.out_of_company"
                    clearable
                    :items="thirdPartyOptions"
                    v-model="form.third_party"
                    label="Third Party"
                    prepend-icon="mdi-domain"
                    item-text="name"
                    hide-details
                    return-object
                    @input="handleThirdPartyPicked"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                    v-model="form.option"
                    :items="optionOptions"
                    prepend-icon="mdi-apple-keyboard-option"
                    item-text="label"
                    item-value="value"
                    clearable
                    label="Rent/Buy"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-model="form.drivers_phone"
                    label="Driver's Phone"
                    prepend-icon="mdi-phone"
                />
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.start_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.start_date"
                        label="Start Date"
                        prepend-icon="mdi-calendar"
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      :value="form.start_date"
                      @input="
                      (v) => {
                        $refs.startDateMenu.save(v);
                        startDateMenu = false;
                      }
                    "
                      no-title
                      scrollable
                  />
                </v-menu>
              </v-col>

            </v-row>
            <!--   IS CURRENT DRIVER         -->
            <template v-if="form.in_company">
              <v-row
                  v-if="showMainForm && editItem !== 'new'"
                  :class="{ 'TrailerForm__border-bottom': editItem !== 'new' }"
              >
                <v-col cols="12" sm="8" md="6">
                  <v-checkbox
                      v-model="form.is_current_driver"
                      label="Is Current Driver"
                      name="is_current_driver"
                  />
                </v-col>
              </v-row>
              <v-row
                  v-if="showMainForm && editItem !== 'new' && form.is_current_driver"
                  :class="{ 'TrailerForm__border-bottom': editItem !== 'new' }"
              >
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                      v-if="form.driver_state !== 'temporary'"
                      v-model="form.current_driver_name"
                      :items="currentDriverOptions"
                      :loading="loading"
                      :search-input.sync="searchDriversCurrent"
                      label="Current Driver"
                      placeholder="Start typing to search"
                      prepend-icon="mdi-database-search"
                      clearable
                      hide-details
                      hide-no-data
                      @change="handleCurrentDriverSelected"
                      @click:clear="handleClearDriverCurrent"
                  >
                  </v-autocomplete>
                  <v-text-field
                      v-else
                      v-model="form.current_driver_name"
                      label="Driver"
                      prepend-icon="mdi-card-account-details-outline"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                      v-model="form.current_driver_truck"
                      :items="trucksListFormatedCurrent"
                      :search-input.sync="searchTrucksCurrent"
                      :loading="loading"
                      :filter="clientFilter"
                      label="Current Truck"
                      item-text="truck_id"
                      item-disabled="disabled"
                      hide-details="auto"
                      placeholder="Start typing to Search"
                      prepend-icon="mdi-database-search"
                      name="current_truck"
                      clearable
                      return-object
                      hide-no-data
                      @click:clear="handleClearDriverCurrent"
                      @input="handleSelectTruckCurrent"
                  >
                    <template v-slot:item="{ item }">
                      <span :class="{ isExternal: item.is_external }">
                        {{ `${item.truck_id} - ${item.name}` }}
                        <span v-if="item.is_external">external</span>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu
                      ref="startDateMenuCurrentDriver"
                      v-model="startDateMenuCurrentDriver"
                      :close-on-content-click="false"
                      :return-value.sync="form.start_date_current_driver"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="form.start_date_current_driver"
                          label="Start Date Current Driver"
                          prepend-icon="mdi-calendar"
                          clearable
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      />
                    </template>
                    <v-date-picker
                        :value="form.start_date_current_driver"
                        @input="
                      (v) => {
                        $refs.startDateMenuCurrentDriver.save(v);
                        startDateMenuCurrentDriver = false;
                      }
                    "
                        no-title
                        scrollable
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                      v-model="form.driver_state_current_driver"
                      :items="driverStateOptions"
                      prepend-icon="mdi-apple-keyboard-option"
                      label="Driver State Current Driver"
                      item-value="value"
                      item-text="text"
                      clearable
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="form.current_driver_company"
                    :items="companiesAll"
                    label="Company Current Driver"
                    prepend-icon="mdi-domain"
                    item-text="name"
                    clearable
                    hide-details
                    return-object
                  />
                </v-col>

              </v-row>
            </template>
            <!--   REST OF THE FIELDS         -->
            <template v-if="editItem !== 'new'">
              <v-row class="TrailerForm__border-bottom">
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    ref="driverPUDateMenu"
                    v-model="driverPUDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.driver_pu_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.driver_pu_date"
                        label="Driver Pick Up Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      :value="form.driver_pu_date"
                      @input="
                        (v) => {
                          $refs.driverPUDateMenu.save(v);
                          driverPUDateMenu = false;
                        }
                      "
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    clearable
                    :items="yards"
                    v-model="form.yard"
                    label="Yard"
                    prepend-icon="mdi-grave-stone"
                    item-text="name"
                    hide-details
                    return-object
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="form.plate"
                    v-validate="'max:12'"
                    :error-messages="errors.first('trailer.plate')"
                    :error="errors.has('trailer.plate')"
                    label="Plate"
                    name="plate"
                    prepend-icon="mdi-id-card"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    ref="plateExpDateMenu"
                    v-model="plateExpDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.temp_plate_exp_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.temp_plate_exp_date"
                        label="Plate Exp Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      :value="form.temp_plate_exp_date"
                      @input="
                        (v) => {
                          $refs.plateExpDateMenu.save(v);
                          plateExpDateMenu = false;
                        }
                      "
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    ref="dotInspectionDateMenu"
                    v-model="dotInspectionDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.dot_inspection_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.dot_inspection_date"
                        label="DOT"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      :value="form.dot_inspection_date"
                      @input="
                        (v) => {
                          $refs.dotInspectionDateMenu.save(v);
                          dotInspectionDateMenu = false;
                        }
                      "
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="form.gps"
                    label="GPS"
                    @change="handleGPSChange"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="form.gps_app"
                    :disabled="Boolean(!form.gps)"
                    :items="gpsAppOptions"
                    label="GPS App"
                    prepend-icon="mdi-application"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="form.gps_working"
                    :disabled="Boolean(!form.gps)"
                    label="GPS Working"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="form.has_partner_sticker"
                    label="Sajne"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="form.location_gps"
                    label="Location GPS"
                    prepend-icon="mdi-map-marker"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox v-model="form.towing" label="Towing" />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="form.towing_per_day"
                    :disabled="Boolean(!form.towing)"
                    label="Per Day"
                    type="number"
                    prepend-icon="mdi-currency-usd"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="form.units_together"
                    label="Units Together"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox v-model="form.is_active" label="Is Active" />
                </v-col>
              </v-row>

              <v-row class="TrailerForm__border-bottom">
                <v-col v-if="!form.is_current_driver" cols="12" sm="6" md="4">
                  <v-select
                    v-model="form.driver_state"
                    :items="driverStateOptions"
                    prepend-icon="mdi-apple-keyboard-option"
                    label="Driver State"
                    :error="errors.has('trailer.driver_state')"
                    :error-messages="errors.first('trailer.driver_state')"
                    item-value="value"
                    item-text="text"
                    clearable
                    @change="driver_name = null"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    clearable
                    :items="ownersOptions"
                    v-model="form.owner_new"
                    label="Owner"
                    prepend-icon="mdi-domain"
                    item-text="name"
                    return-object
                    hide-details
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="form.is_damaged"
                    label="Broken"
                    @change="form.is_total_damaged = false"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="form.is_total_damaged"
                    :disabled="Boolean(!form.is_damaged)"
                    label="Undrivable"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-checkbox v-model="form.loaded" label="Loaded" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    label="Status"
                    v-model="form.status"
                    :items="statusOptions"
                    :error="errors.has('trailer.status')"
                    :error-messages="errors.first('trailer.status')"
                    prepend-icon="mdi-list-status"
                    @input="handleStatusChange"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    label="Brokerage"
                    v-model="form.brockerage"
                    :items="brockerageOptions"
                    :disabled="form.status !== 'BROCKERAGE'"
                    clearable
                    prepend-icon="mdi-list-statuFs"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    label="Availability"
                    v-model="form.availability"
                    :items="['unavailable', 'available']"
                    prepend-icon="mdi-list-status"
                    :error="errors.has('trailer.availability')"
                    :error-messages="errors.first('trailer.availability')"
                    name="availability"
                    clearable
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    label="Hold"
                    v-model="form.hold"
                    :items="holdOptions"
                    return-object
                    item-text="name"
                    prepend-icon="mdi-account"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    ref="holdDateMenu"
                    v-model="holdDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.hold_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.hold_date"
                        label="Hold Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      :value="form.hold_date"
                      @input="
                        (v) => {
                          $refs.holdDateMenu.save(v);
                          holdDateMenu = false;
                        }
                      "
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="reset"> Reset </v-btn>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" type="submit" text> Save </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import usersService from '../../services/UsersService'
import callsService from '../../services/CallsService'
import trailerTypeService from '../../services/TrailerTypesService'
import trailerMarksService from '../../services/TrailerMarksService'
import service from '../../services/TrailersService'
import TrucksService from '../../services/TruckService'

const REQUIRED = ['option']

const DEFAULT_ITEM = {
  gps: null,
  gps_working: null,
  has_partner_sticker: null,
  is_damaged: null,
  is_total_damaged: null,
  is_active: null,
  loaded: null,
  towing: null,
  availability: null,
  status: 'NO STATUS',
  brockerage: null,
  option: null,
  is_for_sell: null,
  hold: null,
  hold_date: null,
  units_together: null,
  active_type: null,
  _id: null,
  truck: null,
  driver_name: null,
  owner: null,
  drivers_phone: null,
  drivers_id: null,
  current_drivers_id: null,
  pu_date: null,
  start_date: null,
  driver_pu_date: null,
  make: null,
  vin_number: null,
  plate: null,
  temp_plate_exp_date: null,
  temporary_third_party: false,
  dot_inspection_date: null,
  production_year: null,
  createdAt: null,
  updatedAt: null,
  __v: null,
  gps_app: null,
  yard: null,
  yellowType: null,
  third_party: null,
  dealership: null,
  company: null,
  towing_per_day: null,
  location_gps: null,
  in_company: null,
  out_of_company: null,
  is_current_driver: null,
  current_driver_name: null,
  current_driver_truck: null,
  current_driver_company: null,
  start_date_current_driver: null,
  driver_state_current_driver: null
}

export default {
  name: 'TrailerForm',

  props: {
    editId: {
      type: String,
      required: false,
      default: null,
    },
    editItem: {
      type: [Object, String],
      required: false,
    },
    isAvailableForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['save', 'close'],

  data() {
    return {
      dialog: false,
      form: { ...DEFAULT_ITEM },
      tempDriverError: false,
      loading: false,

      optionOptions: [
        { label: 'Rent', value: 'RENT' },
        { label: 'Buy', value: 'BUY' },
      ],
      brockerageOptions: ['Available', 'Picked Up', 'Booked'],
      driverStateOptions: [
        { text: 'Temporary', value: 'temporary' },
        { text: 'Recovery', value: 'recovery' },
        { text: 'Repower', value: 'repower' },
      ],
      statusOptions: [
        { text: 'NO STATUS', value: 'NO STATUS' },
        { text: 'STOLEN', value: 'STOLEN' },
        { text: 'REPOSSESSION', value: 'REPOSSESSION' },
        { text: 'REPOWER', value: 'REPOWER' },
        { text: 'NEW', value: 'NEW' },
        { text: 'ON THE ROAD', value: 'ON THE ROAD' },
        { text: 'SOLO', value: 'SOLO' },
        { text: 'BROKERAGE', value: 'BROCKERAGE' },
        { text: 'PARKED', value: 'PARKED' },
        { text: 'MISSING', value: 'MISSING' },
        { text: 'RECOVERY', value: 'RECOVERY' },
        { text: 'CLAIMS', value: 'CLAIMS' },
        { text: 'FOR SALE', value: 'FOR SALE' },
        { text: 'AUCTION', value: 'AUCTION' },
        { text: 'YARD', value: 'YARD' },
        { text: 'DROP AND HOOK', value: 'DROP AND HOOK ' },
      ],
      gpsAppOptions: ['AZ', 'LX', 'SA', 'Samsara NO A', 'Sa destroyed sa lb', 'SA + AZ', 'SA + LX'],
      ownersOptions: [],
      thirdPartyOptions: [],
      companies: [],
      dealerships: [],
      yards: [],
      holdOptions: [],
      driverOptions: [],
      currentDriverOptions: [],
      truckOptions: [],
      currentTruckOptions: [],
      makeOptions: [],
      trailerTypes: [],

      searchDrivers: '',
      searchDriversCurrent: '',
      searchTrucks: '',
      searchTrucksCurrent: '',
      selected: {},

      driverPUDateMenu: false,
      puDateMenu: false,
      startDateMenu: false,
      startDateMenuCurrentDriver: false,
      holdDateMenu: false,
      plateExpDateMenu: false,
      dotInspectionDateMenu: false,
      vinPrefix: null,
    }
  },

  watch: {
    editItem: {
      handler(v) {
        this.dialog = !!v
        if (v)
          this.form =
            v === 'new'
              ? { ...DEFAULT_ITEM, status: 'NEW', is_active: true }
              : { ...v }
        if (v && v.driver_name) this.driverOptions = [v.driver_name]
        if (v && v.current_driver_name) this.currentDriverOptions = [v.current_driver_name]
        if (v && v.truck) {
          this.truckOptions = [v.truck]
          this.form.truck = this.formatTruck(v.truck)
        }
        if (v && v.current_driver_truck) {
          this.currentTruckOptions = [v.current_driver_truck]
          this.form.current_driver_truck = this.formatTruck(v.current_driver_truck)
        }
      },
      immediate: true,
    },

    searchDrivers(val) {
      if (val) this.debounceGetDrivers(val)
    },
    searchDriversCurrent(val) {
      if (val) this.debounceGetDriversCurrent(val)
    },
    searchTrucks(val) {
      if (val) this.debounceGetTrucks(val)
    },
    searchTrucksCurrent(val) {
      if (val) this.debounceGetTrucksCurrent(val)
    },
  },

  computed: {
    ...mapGetters('common', ['companiesAll']),
    ...mapGetters('auth', ['permissions']),

    formTitle() {
      return !this.editId ? 'New Item' : 'Edit Item'
    },

    submitDisabled() {
      for (const field of REQUIRED) if (!this.form[field]) return true
      return false
    },

    trucksListFormated() {
      return this.truckOptions.map(this.formatTruck)
    },
    trucksListFormatedCurrent() {
      return this.currentTruckOptions.map(this.formatTruck)
    },

    showMainForm() {
      if (this.editItem === 'new') return true
      return this.permissions.TRAILER_SUPER_EDIT
    },
    productionYears() {
      let years = []
      for (let year = 2000; year <= 2050; year++) {
        years.push(year)
      }
      return years
    }
  },

  created() {
    this.getDealerships()
    this.getYards()
    this.getOwners()
    this.getSalesmen()
    this.getTrailerTypes()
    this.getTrailerMakes()
  },

  reset() {
    this.form.driver_name = null
    this.form.truck = null
    this.form.drivers_phone = null
    this.form.third_party = null
    this.form.pu_date = null
    this.form.option = null
  },

  methods: {
    handleGPSChange() {
      this.form.gps_app = null
      this.form.gps_working = false
    },

    formatTruck(item) {
      item.disabled = false
      item.text = `${item.truck_id} - ${item.name}`
      return item
    },

    handleMakePicked(m) {
      if (m && m.vin_prefix) this.vinPrefix = m.vin_prefix
      else this.vinPrefix = null
    },

    handleThirdPartyPicked() {
      this.handleClearDriver()
    },

    async handleSelectTruck(t) {
      if (!t) return
      this.driverOptions = [t.name]
      this.form.driver_name = t.name
      this.form.drivers_phone = t.phone_number
      this.form.drivers_id = t.id
      await this.fetchCompany(t.truck_id, false)
      this.handleClearThirdParty()
    },
    async fetchCompany(truckNumber, currentFlag) {
      try {
        const response = await TrucksService.getTruckByNumber(truckNumber)
        if( currentFlag ) {
          this.form.current_driver_company = response.data.truck.division
        } else {
          this.form.company = response.data.truck.division
        }
      } catch (error) {
        console.error('Error fetching truck copmany:', error)
      }
    },
    async handleSelectTruckCurrent(t) {
      if (!t) return
      this.currentDriverOptions = [t.name]
      this.form.current_driver_name = t.name
      this.form.current_drivers_id = t.id
      await this.fetchCompany(t.truck_id, true)
    },

    handleClearThirdParty() {
      this.form.temporary_third_party = false
      this.form.third_party = null
    },

    handleClearDriver() {
      this.driverOptions = []
      this.form.driver_name = null
      this.form.drivers_phone = null
      this.form.drivers_id = null
      this.form.company = null
      this.form.truck = null
    },
    handleClearDriverCurrent() {
      this.currentDriverOptions = []
      this.form.current_driver_name = null
      this.form.current_drivers_id = null
      this.form.current_driver_company = null
      this.form.current_driver_truck = null
      this.form.current_driver_truck = null
    },

    clientFilter(item, queryText) {
      return (
        item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      )
    },

    handleStatusChange(v) {
      if (v !== 'BROCKERAGE') this.form.brockerage = false
    },

    getDealerships() {
      this.$store
        .dispatch('dealerships/getAllDealerships', 'TRAILER')
        .then((res) => {
          this.dealerships = res
        })
    },

    getYards() {
      this.$store.dispatch('yards/getAllYards', 'TRAILER').then((res) => {
        this.yards = res
      })
    },

    getOwners() {
      this.$store.dispatch('trailers/getAllOwners').then((res) => {
        this.ownersOptions = res.filter((o) => !o.is_company)
        this.thirdPartyOptions = res.filter((o) => o.is_company)
      })
    },

    async getTrailerTypes() {
      try {
        const { data } = await trailerTypeService.getAllTrailerTypes()
        if (Array.isArray(data.trailerTypes))
          this.trailerTypes = data.trailerTypes.map((type) => type.name)
      } catch (error) {
        console.error('getTrailerTypes()', error)
      }
    },

    formattedMake(item) {
      if (!item.vin_prefix) return item.name
      return `${item.name} - ${item.vin_prefix}`
    },

    async getTrailerMakes() {
      try {
        const { data } = await trailerMarksService.getAllTrailerMarks()
        if (Array.isArray(data.trailerMarks))
          this.makeOptions = [...data.trailerMarks]
      } catch (error) {
        console.error('getTrailerMakes()', error)
      }
    },

    async getSalesmen() {
      try {
        const { data } = await usersService.getUsersByRoleId(22)
        if (Array.isArray(data)) this.holdOptions = data
      } catch (error) {
        console.error('getSalesmen()', error)
      }
    },

    debounceGetTrucks: debounce(async function (val) {
      if (val && val.length) {
        try {
          const { data } = await callsService.getMergedDrivers(val)
          this.truckOptions = data.drivers
        } catch (error) {
          console.error('debounceGetTrucks', error)
        } finally {
          this.loading = false
        }
      }
    }, 400),
    debounceGetTrucksCurrent: debounce(async function (val) {
      if (val && val.length) {
        try {
          const { data } = await callsService.getMergedDrivers(val)
          this.currentTruckOptions = data.drivers
        } catch (error) {
          console.error('debounceGetTrucks', error)
        } finally {
          this.loading = false
        }
      }
    }, 400),

    debounceGetDrivers: debounce(async function (val) {
      if (val && val.length) {
        try {
          const { data } = await callsService.getMergedDrivers(val)
          this.driverOptions = data.drivers.map((d) => d.name)
        } catch (error) {
          console.error('debounceGetDrivers', error)
        } finally {
          this.loading = false
        }
      }
    }, 400),
    debounceGetDriversCurrent: debounce(async function (val) {
      if (val && val.length) {
        try {
          const { data } = await callsService.getMergedDrivers(val)
          this.currentDriverOptions = data.drivers.map((d) => d.name)
        } catch (error) {
          console.error('debounceGetDrivers', error)
        } finally {
          this.loading = false
        }
      }
    }, 400),

    async handleDriverSelected(val) {
      if (val) {
        try {
          const { data } = await callsService.getMergedDrivers(val)
          if (Array.isArray(data.drivers) && data.drivers[0]) {
            this.truckOptions = [data.drivers[0]]
            this.form.truck = this.formatTruck(data.drivers[0])
            this.form.drivers_phone = data.drivers[0].phone_number
            this.form.drivers_id = data.drivers[0].id
            await this.fetchCompany(this.form.truck.truck_id, false)
            this.handleClearThirdParty()
          }
        } catch (error) {
          console.error('debounceGetTrucks', error)
        } finally {
          this.loading = false
        }
      }
    },
    async handleCurrentDriverSelected(val) {
      if (val) {
        try {
          const { data } = await callsService.getMergedDrivers(val)
          if (Array.isArray(data.drivers) && data.drivers[0]) {
            this.currentTruckOptions = [data.drivers[0]]
            this.form.current_driver_truck = this.formatTruck(data.drivers[0])
            // this.form.drivers_phone = data.drivers[0].phone_number
            this.form.current_drivers_id = data.drivers[0].id
            await this.fetchCompany(this.form.current_driver_truck.truck_id, true)
          }
        } catch (error) {
          console.error('debounceGetTrucks', error)
        } finally {
          this.loading = false
        }
      }
    },

    checkClientErrors() {
      let error = false
      const {
        status,
        driver_state,
        driver_name,
        third_party,
        owner_new,
        temporary_third_party,
        availability,
      } = this.form
      if (driver_state === 'temporary' && status === 'NO STATUS') {
        error = true
        this.errors.add({
          scope: 'trailer',
          field: 'driver_state',
          msg: 'Temporary only allowed with a status.',
        })
        this.errors.add({
          scope: 'trailer',
          field: 'status',
          msg: 'Must have a status for temporary driver.',
        })
      } else if (!availability && status !== 'NO STATUS') {
        error = true
        this.errors.add({
          scope: 'trailer',
          field: 'availability',
          msg: 'This field must not be empty for trailers with a status.',
        })
      } else if (temporary_third_party && status === 'NO STATUS') {
        error = true
        this.errors.add({
          scope: 'trailer',
          field: 'temporary_third_party',
          msg: 'Temporary only allowed with a status.',
        })
        this.errors.add({
          scope: 'trailer',
          field: 'status',
          msg: 'Must have a status for temporary company.',
        })
      } else if (
        status === 'NO STATUS' &&
        !driver_name &&
        !third_party &&
        !owner_new
      ) {
        error = true
        this.errors.add({
          scope: 'trailer',
          field: 'status',
          msg: 'When no driver & owner/third party this is required.',
        })
      }

      return error
    },

    save() {
      this.$validator.validateAll('trailer').then(async (valid) => {
        if (valid) {
          if (this.editItem !== 'new') {
            const hasClientError = this.checkClientErrors()
            if (hasClientError) return
          }

          this.loading = true
          try {
            const fn = this.editId ? 'editTrailer' : 'addTrailer'
            await service[fn](this.form)

            this.$emit('save')
            this.close()
          } catch (error) {
            if (error.data && error.data.code === 11000) {
              const key = Object.keys(error.data.keyPattern)[0]
              this.errors.add({
                scope: 'trailer',
                field: key,
                msg: 'This field must be unique',
              })
            } else console.error('save()', error)
          } finally {
            this.loading = false
          }
        }
      })
    },

    close() {
      this.form = Object.assign({}, DEFAULT_ITEM)
      this.vinPrefix = null
      this.$emit('close')
    },

    reset() {
      this.form.driver_name = null
      this.form.truck = null
      this.form.drivers_phone = null
      this.form.third_party = null
      this.form.pu_date = null
      this.form.option = null
      this.form.in_company = null
      this.form.out_of_company = null 
      this.form.is_current_driver = null
      this.form.start_date = null
      this.form.company = null
      this.form.current_driver_name = null
      this.form.current_driver_truck = null
      this.form.current_driver_company = null
      this.form.start_date_current_driver = null
      this.form.driver_state_current_driver = null
    },
  },
}
</script>

<style lang="scss">
.TrailerForm {
  &__border-bottom {
    border-bottom: 2px solid #1e88e5;
    margin-bottom: 14px;
  }
}
</style>
