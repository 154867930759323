<template>
  <v-card class="TrailersStatistics">
    <v-card-title>Trailers Statistics</v-card-title>
    <div
      style="
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
      v-if="loading"
    >
      <v-progress-circular indeterminate />
    </div>
    <div v-else-if="error" style="padding: 20px">
      <v-alert type="warning">{{ error }}</v-alert>
    </div>
    <div v-else-if="!charts" style="padding: 20px">
      <v-alert type="warning">Something went wrong!</v-alert>
    </div>
    <div v-else style="padding: 20px">
      <v-layout row wrap>
        <v-flex
          v-for="c in charts"
          :key="c.key"
          class="chart-wrapper"
          lg3
          sm4
          xs12
        >
          <pillar-chart
            :chart-data="c.data"
            :title="$t(`other.charts.${c.key}`)"
          />
          <div v-if="yearStats || makeStats || typeStats" class="TrailersStatistics__trailer-year-stats">
            <year-stats v-if="c.key === 'trailersByYear'" :data="yearStats.total" title="Ukupan broj trailera" />
            <year-stats v-if="c.key === 'thirdParty'" :data="yearStats.inCompany" title="Ukupan broj trailera u firmi" />
            <year-stats v-if="c.key === 'thirdParty'" :data="yearStats.outOfCompany" title="Ukupan broj trailera van firme" />
            <type-stats v-if="c.key === 'trailersByType'" :data="typeStats.type" title="Ukupan broj trailera po tipu" />
            <make-stats v-if="c.key === 'trailerMakeStats'" :data="makeStats.make" title="Ukupan broj trailera po make-u" />
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import service from '../../services/StatististcsPage'
import PillarChart from './PillarChart.vue'
import YearStats from './YearStats.vue'
import TypeStats from './TypeStats.vue'
import MakeStats from './MakeStats.vue'



const COLORS = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#3b3eac',
  '#e67300',
  '#a64dff',
  '#ffdb4d',
  '#00e64d',
  '#4da6ff',
  '#ff80b3',
  '#ffb366',
  '#6666ff',
  '#ff4da6',
  '#1c2a39',
  '#f28500',
  '#7d3c98',
  '#138d75',
  '#d5dbdb'
]

export default {
  name: 'StatististicsPage',

  components: { PillarChart, YearStats, TypeStats, MakeStats },

  data() {
    return {
      loading: false,
      charts: null,
      error: null,
      yearStats: null,
      typeStats: null,
      makeStats: null
    }
  },

  mounted() {
    this.getCharts()
    this.getTrailersYearStats()
    this.getTrailersTypeStats()
    this.getTrailersMakeStats()
  },

  methods: {
    async getCharts() {
      this.loading = true
      this.error = null
      try {
        const { data } = await service.getTrailersStatistics()
        if (data.charts) this.fillData(data.charts)
        else if (data.error) this.error = data.error
      } catch (error) {
        if (error && error.data && error.data.error)
          this.error = error.data.error
        else this.error = error
        console.warn(error)
      } finally {
        this.loading = false
      }
    },

    async getTrailersYearStats() {
      try {
        const { data } = await service.getTrailersYearStats()
        const { total, inCompany, outOfCompany } = data
        this.yearStats = {
          total: {
            totalItems: total.totalItems,
            itemsByYear: total.itemsByYear.sort(
              (a, b) => Number(b.year) - Number(a.year)
            ),
          },
          inCompany: {
            totalItems: inCompany.totalItems,
            itemsByYear: inCompany.itemsByYear.sort(
              (a, b) => Number(b.year) - Number(a.year)
            ),
          },
          outOfCompany: {
            totalItems: outOfCompany.totalItems,
            itemsByYear: outOfCompany.itemsByYear.sort(
              (a, b) => Number(b.year) - Number(a.year)
            ),
          },
        }
      } catch (error) {
        console.error('getYearStats()', error)
      } finally {
        this.loading = false
      }
    },

    async getTrailersTypeStats() {
      try {
        const { data } = await service.getTrailersTypeStats()
        const { type } = data
        this.typeStats = {
          type: {
            totalItems: type.totalItems,
            itemsByType: type.itemsByType.sort(
              (a, b) => Number(b.type) - Number(a.type)
            ),
          },
        }
      } catch (error) {
        console.error('getYearStats()', error)
      } finally {
        this.loading = false
      }
    },

    async getTrailersMakeStats() {
      try {
        const { data } = await service.getTrailersMakeStats()
        const { make } = data
        this.makeStats = {
          make: {
            totalItems: make.totalItems,
            itemsByMake: make.itemsByMake.sort(
              (a, b) => Number(b.make) - Number(a.make)
            ),
          },
        }
      } catch (error) {
        console.error('getMakeStats()', error)
      } finally {
        this.loading = false
      }
    },

    fillData(data) {
      const charts = []
      Object.keys(data).forEach((key, i) => {
        const d = data[key]
        const colors = Object.keys(d).map((_, i) => COLORS[i])
        charts[i] = {
          key,
          data: {
            labels: Object.keys(d).map((k) => k),
            datasets: [
              {
                data: Object.values(d).map((v) => v),
                backgroundColor: colors,
              },
            ],
          },
        }
      })
      this.charts = charts
      console.log('this.charts', this.charts)
    },
  },
}
</script>

<style lang="scss">
.TrailersStatistics {
  padding-bottom: 50px;
  overflow-x: scroll;

  &__trailer-year-stats {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    & > *:not(:last-child) {
      margin-right: 20px;
      margin-bottom: 20px
    }
  }
}

.chart-wrapper {
  canvas {
    height: 350px;
    width: 350px;
  }
}
</style>
